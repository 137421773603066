<!--
 * @Author: Haoxiwei 1078734933@qq.com
 * @Date: 2023-04-27 11:09:56
 * @LastEditors: Haoxiwei 1078734933@qq.com
 * @LastEditTime: 2024-11-04 17:45:49
 * @FilePath: /wemeet-admin-new/src/views/exhibitionManage/relatedForum.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <el-tabs v-model="activeName" class="demo-tabs" @tab-click="handleClick">
    <el-tab-pane label="已关联论坛" name="first">
      <vab-query-form style="padding-top: 20px">
        <vab-query-form-left-panel :span="12">
          <el-button type="success" @click="exportData(row, $event)">
            数据导出
          </el-button>
        </vab-query-form-left-panel>
        <vab-query-form-right-panel :span="12">
          <el-form :inline="true" :model="queryForm1" @submit.prevent>
            <el-form-item label="会议名称">
              <el-input v-model="queryForm1.keyword" clearable placeholder="请输入会议名称" />
            </el-form-item>
            <el-form-item>
              <el-button :icon="Search" type="primary" @click="queryData">
                查询
              </el-button>
            </el-form-item>
          </el-form>
        </vab-query-form-right-panel>
      </vab-query-form>
      <el-table v-loading="loading1" border :data="meetList">
        <el-table-column label="序号" type="index" width="50" />
        <el-table-column label="论坛名称" prop="meetingName" show-overflow-tooltip />
        <el-table-column label="论坛开始时间" prop="startTime" show-overflow-tooltip>
          <template #default="{ row }">
            {{ handleDate(row.startTime) }}
          </template>
        </el-table-column>
        <el-table-column label="论坛结束时间" prop="company" show-overflow-tooltip>
          <template #default="{ row }">
            {{ handleDate(row.endTime) }}
          </template>
        </el-table-column>
        <el-table-column label="主办方姓名" prop="name" show-overflow-tooltip />
        <el-table-column label="主办方账户" prop="phone" show-overflow-tooltip />
        <el-table-column label="关联时间" prop="meetingName" show-overflow-tooltip>
          <template #default="{ row }">
            {{ handleDate(row.meetingTime) }}
          </template>
        </el-table-column>
        <!-- <el-table-column label="会议码" prop="qrCodeUrl" show-overflow-tooltip>
          <template #default="{ row }">
            <el-image style="width: 50px; height: 50px" :src="row.qrCodeUrl" :zoom-rate="1.2" :preview-src-list="[row.qrCodeUrl]" :initial-index="4" fit="cover" />
          </template>
        </el-table-column> -->
        <el-table-column align="center" label="操作" show-overflow-tooltip width="300">
          <template #default="{ row }">
            <div class="d-flex flex-wrap flex-center">
              <el-button size="small" plain type="danger" @click="cancelRelatedMeetFunc(row)">
                取消关联
              </el-button>
              <el-button size="small" plain type="success" @click="handleChannel(row)">
                渠道管理
              </el-button>
              <el-button type="primary" size="small" @click="handleQrcode(row)">
                预览二维码
              </el-button>
            </div>
          </template>
        </el-table-column>
        <template #empty>
          <el-empty class="vab-data-empty" description="暂无数据" />
        </template>
      </el-table>
      <el-dialog title="二维码" v-model="dialogVisible" width="30%">
        <template v-for="(item, index) in qrcodeUrlList">
          <el-image :src="item.qrcodeUrl" style="margin: 0 auto"></el-image>
          <p style="text-align: center">{{ item.appKey }}</p>
        </template>
      </el-dialog>
      <el-pagination background :current-page="queryForm1.pageNum" :layout="layout" :page-size="queryForm.pageSize" :total="total1" @current-change="handleCurrentChange1" @size-change="handleSizeChange1" />

      <export-register ref="exportRegisterRef"></export-register>
    </el-tab-pane>
    <el-tab-pane label="关联主办方" name="second">
      <vab-query-form class="ml-10" style="padding-top: 20px">
        <vab-query-form-left-panel :span="24">
          <el-button icon="Search" type="primary" @click="addZhuban">
            添加关联主办方
          </el-button>
        </vab-query-form-left-panel>
      </vab-query-form>
      <el-table v-loading="loading" border :data="list">
        <el-table-column label="序号" type="index" width="50" />
        <el-table-column label="主办方账户" prop="phone" show-overflow-tooltip />
        <el-table-column label="主办方账户" prop="name" show-overflow-tooltip />
        <el-table-column label="公司/组织" prop="company" show-overflow-tooltip />
        <el-table-column label="职位" prop="position" show-overflow-tooltip />
        <el-table-column label="关联会议数量" prop="meetingCount" show-overflow-tooltip />
        <el-table-column label="关联时间" prop="meetingName" show-overflow-tooltip></el-table-column>
        <el-table-column align="center" label="操作" show-overflow-tooltip width="300">
          <template #default="{ row }">
            <div class="d-flex flex-wrap flex-center">
              <el-button size="small" plain type="primary" @click="handleMeet(row)">
                关联会议
              </el-button>
              <el-button size="small" plain type="danger" @click="cancelRelatedFunc(row)">
                取消关联
              </el-button>
            </div>
          </template>
        </el-table-column>
        <template #empty>
          <el-empty class="vab-data-empty" description="暂无数据" />
        </template>
      </el-table>

      <el-pagination background :current-page="queryForm.pageNum" :layout="layout" :page-size="queryForm.pageSize" :total="total" @current-change="handleCurrentChange" @size-change="handleSizeChange" />

      <edit ref="editRef" :meetingCode="queryForm.meetingCode" @fetch-data="fetchData" />
    </el-tab-pane>
  </el-tabs>

</template>

<script>
import {
  defineComponent,
  getCurrentInstance,
  defineAsyncComponent,
  onMounted,
  reactive,
  toRefs,
  watch,
} from 'vue'
import {
  getHasRelatedOrgList,
  cancelRelated,
  getAllHasRelatedMeet,
  cancelRelatedMeet,
  getQrcodeUrls,
} from '@/api/exhibitionManage'
import { parseTime } from '@/utils/index'
import { useRoute } from 'vue-router'
import { dataExportB } from '@/api/exhibitionManage'
import exportRegister from './components/exportRegister.vue'
export default defineComponent({
  name: 'relatedForum.vue',
  components: {
    Edit: defineAsyncComponent(() => import('./components/editRelated')),
    exportRegister,
  },
  setup() {
    const state = reactive({
      activeName: 'first',
      loading: false,
      loading1: false,
      dialogVisible: false,
      list: [],
      qrcodeUrlList: [],
      editRef: null,
      queryForm: {
        meetingCode: '',
        pageNum: 1,
        pageSize: 20,
      },
      queryForm1: {
        exMeetingCode: '',
        pageNum: 1,
        pageSize: 20,
        keyword: '',
      },
      total: 0,
      total1: 0,
      layout: 'prev, pager, next',
      meetList: [],
      exportRegisterRef: null,
    })

    const { proxy } = getCurrentInstance()
    const route = useRoute()
    const addZhuban = (row) => {
      if (row) {
        state['editRef'].showEdit(row)
      } else {
        state['editRef'].showEdit()
      }
    }
    watch(
      () => route.query.meetingCode,
      (count, prevCount) => {
        console.log('我遍了', count)
        if (!!count) {
          state.queryForm1.exMeetingCode = count
          fetchMeetData()
        }

        // state.alreadyChoose = count
      }
    )
    const handleClick = (tab, event) => {
      if (tab.props.name == 'first') {
        state.queryForm1.pageNum = 1
        fetchMeetData()
      } else {
        fetchData()
      }
    }

    const handleChannel = (row) => {
      if (row.meetingCode) {
        proxy.$router.push({
          path: '/exhibitionManage/channelDefend',
          query: { meetingCode: row.meetingCode, type: 'HOLD_MEETING' },
        })
      }
    }

    const handleQrcode = (row) => {
      getQrcodeUrls(row.meetingCode, 'HOLD_MEETING').then((res) => {
        console.log(res)
        state.qrcodeUrlList = res.data
        state.dialogVisible = true
      })
    }

    const fetchData = async () => {
      const { data } = await getHasRelatedOrgList(state.queryForm)
      state.list = data.data
      state.total = data.total
    }

    const fetchMeetData = async () => {
      const { data: meetList } = await getAllHasRelatedMeet(state.queryForm1)
      state.meetList = meetList.data
      state.total1 = meetList.total
    }

    onMounted(() => {
      console.log(proxy.$route)
      state.queryForm.meetingCode = proxy.$route.query.meetingCode
      state.queryForm1.exMeetingCode = proxy.$route.query.meetingCode
      fetchMeetData()
      fetchData()
    })
    const queryData = () => {
      state.queryForm1.pageNum = 1
      fetchMeetData()
    }

    // 条数
    const handleSizeChange1 = async (pageSize) => {
      state.queryForm1.pageSize = pageSize
      fetchMeetData()
    }
    // 页码
    const handleCurrentChange1 = async (pageNum) => {
      state.queryForm1.pageNum = pageNum
      fetchMeetData()
    }

    // 条数
    const handleSizeChange = async (pageSize) => {
      state.queryForm.pageSize = pageSize
      fetchData()
    }
    // 页码
    const handleCurrentChange = async (pageNum) => {
      state.queryForm.pageNum = pageNum
      fetchData()
    }

    // 取消关联
    const cancelRelatedFunc = async (row) => {
      if (!!row.uid) {
        proxy.$baseConfirm(
          '取消主办方账户的关联，会导致已关联的主办方会议，也被解除绑定。',
          null,
          async () => {
            const { msg } = await cancelRelated(
              row.uid,
              state.queryForm.meetingCode
            )
            proxy.$baseMessage(msg, 'success', 'vab-hey-message-success')
            await fetchData()
          }
        )
      }
    }

    // 取消已经关联的论坛
    const cancelRelatedMeetFunc = async (row) => {
      console.log(row)
      if (!!row.uid) {
        proxy.$baseConfirm(
          '取消会议的关联，会导致展会的论坛列表不再显示这场会议。',
          null,
          async () => {
            const { msg } = await cancelRelatedMeet({
              hostUid: row.uid,
              meetingCode: row.meetingCode,
              exMeetingCode: state.queryForm1.exMeetingCode,
            })
            proxy.$baseMessage(msg, 'success', 'vab-hey-message-success')
            await fetchMeetData()
          }
        )
      }
    }

    // 关联会议
    const handleMeet = (row) => {
      proxy.$router.push({
        path: '/exhibitionManage/relatedMeet',
        query: { hostUid: row.uid, exCode: state.queryForm.meetingCode },
      })
    }
    const handleDate = (val) => {
      return parseTime(val)
    }

    const exportData = async () => {
      // const { data } = await dataExportB({ type : 'EXHIBITION_FORUM_REGISTER_REPORT', relationCode: state.queryForm.meetingCode, relationType: 'EXHIBITION' })
      // console.log(data)

      state.exportRegisterRef.handleOpen(
        {
          type: 'EXHIBITION_FORUM_REGISTER_REPORT',
          code: state.queryForm.meetingCode,
          relationType: 'EXHIBITION',
        },
        `会议数据导出`,
        `EXHIBITION_FORUM_REGISTER_REPORT`
      )
    }

    return {
      ...toRefs(state),
      handleClick,
      addZhuban,
      cancelRelatedFunc,
      fetchData,
      fetchMeetData,
      handleMeet,
      handleSizeChange,
      handleCurrentChange,
      handleDate,
      cancelRelatedMeetFunc,
      handleChannel,
      handleQrcode,
      queryData,
      handleSizeChange1,
      handleCurrentChange1,
      exportData,
    }
  },
})
</script>
<style lang="scss" scoped></style>
